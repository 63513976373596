<template>
  <div>

    <b-nav-item-dropdown
      right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user"
    >
      <template #button-content>
        <div class="d-sm-flex d-none user-nav">
          <p class="user-name font-weight-bolder mb-0">
            {{ userData.full_name || userData.username }}
          </p>
          <span class="user-status">{{ userData.role }}</span>
        </div>
        <b-avatar
          size="40"
          variant="light-primary"
        >
        </b-avatar>
      </template>
  
      <b-dropdown-item
        link-class="d-flex align-items-center"
        v-b-modal.change-password
      >
        <feather-icon
          size="16"
          icon="LockIcon"
          class="mr-50"
        />
        <span>Ganti Password</span>
      </b-dropdown-item>
      <b-dropdown-item
        link-class="d-flex align-items-center"
        @click="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>Keluar</span>
      </b-dropdown-item>
    </b-nav-item-dropdown>

    <b-modal
      id="change-password"
      title="Ganti Password"
      hide-footer
      centered
    >
      <ChangePassword @on-success="logout" />
    </b-modal>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  // BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'
import { avatarText } from '@core/utils/filter'
import ChangePassword from '@/views/pages/ChangePassword.vue'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    // BDropdownDivider,
    BAvatar,
    ChangePassword
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      avatarText,
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

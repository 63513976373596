<template>
  <validation-observer ref="form">
    <b-form @submit.prevent="onSubmit">
      <!-- Body -->
      <b-row>
        <b-col lg="12" v-if="$can('manage', 'all')">
          <!-- Password lama -->
          <b-form-group
            label="Password lama"
            label-for="old-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password lama"
              vid="old-password"
              :rules="$can('manage', 'all') && 'required'"
            >
              <b-form-input
                id="old-password"
                name="old-password"
                type="password"
                v-model="form.oldPassword"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <!-- Password baru -->
          <b-form-group
            label="Password baru"
            label-for="new-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Password baru"
              vid="new-password"
              rules="required"
            >
              <b-form-input
                id="new-password"
                name="new-password"
                type="password"
                v-model="form.newPassword"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <!-- Konfirmasi password baru -->
          <b-form-group
            label="Konfirmasi password baru"
            label-for="confirm-new-password"
          >
            <validation-provider
              #default="{ errors }"
              name="Konfirmasi password baru"
              vid="confirm-new-password"
              rules="required|confirmed:new-password"
            >
              <b-form-input
                id="confirm-new-password"
                name="confirm-new-password"
                type="password"
                v-model="form.confirmNewPassword"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button
        class="button-pilih mr-1"
        variant="primary"
        type="submit"
        :disabled="loading"
        v-text="loading ? 'Mengganti...' : 'Ganti'"
      />
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BCardText,
  BTable,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BFormTextarea,
  BButton,
  BFormGroup,
  BForm,
  BFormDatepicker,
} from 'bootstrap-vue'
import VSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { required, confirmed } from '@validations'
import { getUserData } from '@/auth/utils'

import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    flatPickr,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BCardFooter,
    BCardText,
    BTable,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BFormTextarea,
    BButton,
    BFormGroup,
    BForm,
    ValidationObserver,
    ValidationProvider,
    BFormDatepicker,
    VSelect,
  },
  data() {
    return {
      // form
      form: {
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },

      // loading
      loading: false,

      // validations
      required,
      confirmed
    }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate().then(success => {
        if (success) {
          this.loading = true
          const { role } = getUserData()

          useJwt.http.put('/change-password', { ...this.form, role })
            .then(response => {
              this.$emit('on-success')
              this.$alert({ title: response.data.message, variant: 'success', icon: 'CheckIcon' })
            })
            .catch(error => this.$alert({ title: error.response.data.message, variant: 'danger', icon: 'XIcon' }))
            .finally(() => this.loading = false)
        }
      })
    },
  },
}
</script>